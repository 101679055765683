<script lang="ts" context="module">
	export type AtomTextColor =
		| 'primary'
		| 'secondary'
		| 'secondary-darker'
		| 'danger'
		| 'white'
		| 'orange'
		| 'purple'
		| 'green'
		| 'none' // You can have the "none" color and easily set the color from the class props in the parent component
		| 'primary-theme-color';
</script>

<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	export let type:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'body'
		| 'bodySemiBold'
		| 'bodyBold'
		| 'caption'
		| 'captionBold'
		| 'subtitle'
		| 'subtitleBold'
		| 'button'
		| 'link'
		| 'none'
		| 'overline' = 'body';
	export let underline = false;
	export let cursorPointer = false;
	export let ellipsis = false;
	export let element: undefined | string = undefined;
	export let selectable = false;
	// If we want to add more colors, we can add them here, make sur to add the color in 'getFullClassNameFromColor' too
	export let color: AtomTextColor = 'primary';

	let classNames = '';
	let elementType = 'p';
	let data: HTMLDivElement;

	function getValidElementType() {
		const tmpElementType = element || type;
		if (!['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tmpElementType)) {
			return 'p';
		}
		return tmpElementType;
	}
	function getFullClassNameFromColor(color: string) {
		// We must add the text- prefix to the color and return the full string, otherwise tailwind can have issue to find the color !
		if (color === 'primary') return 'text-primary dark:text-white';
		else if (color === 'secondary') return 'text-secondary dark:text-gray-400';
		else if (color === 'secondary-darker') return 'text-secondary-600 dark:text-gray-200';
		else if (color === 'danger') return 'text-red-500';
		else if (color === 'purple') return 'text-purple-500';
		else if (color === 'green') return 'text-green-500';
		else if (color === 'orange') return 'text-orange-500';
		else if (color === 'white') return 'text-white';
		else if (color === 'none') return '';
		else if (color === 'primary-theme-color') return 'text-primary-theme-color';
		else
			console.error(
				'Color not found in getFullClassNameFromColor() ! You wanted to display a text with this color',
				color
			);
		return '';
	}

	$: classNames = getFullClassNameFromColor(color);
	$: elementType = getValidElementType();

	// If there is a + or a @, it could be a phone or a email so we make the text selectable, it can be useful to select and copy it
	$: _selectable = data?.innerText.includes('+') || data?.innerText.includes('@') || selectable;
</script>

<!-- TODO: Styliser le texte en fonction du type, voir les overrides de tailwind de txt first -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<svelte:element
	this={elementType}
	bind:this={data}
	on:click={() => dispatch('click')}
	class:font-bold={[
		'h1',
		'h2',
		'h3',
		'h4',
		'h5',
		'h6',
		'bodyBold',
		'subtitleBold',
		'captionBold'
	].includes(type)}
	class:select-none={!_selectable}
	class:font-semibold={['bodySemiBold'].includes(type)}
	class:text-6xl={type === 'h1'}
	class:text-5xl={type === 'h2'}
	class:text-4xl={type === 'h3'}
	class:text-3xl={type === 'h4'}
	class:text-2xl={type === 'h5'}
	class:text-xl={type === 'h6'}
	class:text-lg={false}
	class:uppercase={type === 'overline'}
	class:underline={type === 'link' || underline}
	class:cursor-pointer={type === 'link' || cursorPointer}
	class:text-base={['body', 'subtitle', 'button', 'link'].includes(type)}
	class:text-sm={['caption', 'captionBold', 'overline'].includes(type)}
	class:text-xs={$$restProps.class?.includes('text-xs')}
	class:text-ellipsis={ellipsis}
	class:overflow-hidden={ellipsis}
	class:whitespace-nowrap={ellipsis}
	class={`${classNames} ${$$restProps.class ?? ''}`}><slot /></svelte:element
>
